import axios from 'axios';
//const isProd = process.env.NODE_ENV === 'production';
const isProd= true
const instanceApiVenda = axios.create({
    baseURL: (isProd) ? 'https://apivendas.santocartao.com.br/api' : 'http://127.0.0.1:7012/api',
    headers: {
        'Authorization': 'Bearer your-api-key',
        ['keyApiVendas']: '23tbOaox0uYsAO2pKziXQjqZc4TKsZwaLhsjy7JCkywDQZpDv6'
    }
})

const GET_VENDAS = (url: string | void) => instanceApiVenda.get(url || "").then(res => res.data).then(res => {
    return res
}).catch(e => {

    throw e.response.data.message
})

const POST_VENDAS = (url: string | void, params: object | Array<any | void> | void) => instanceApiVenda.post(url || "", params).then(res => res.data).then(res => {
    return res
}).catch(e => {

    throw e.response.data.message
})

const PUT_VENDAS = (url: string | void, params: object | Array<any> | void) => instanceApiVenda.put(url || "", params).then(res => res.data).then(res => {
    return res
}).catch(e => {

    throw e.response.data.message
})

const DELETE_VENDAS = (url: string | void) => instanceApiVenda.delete(url || "").then(res => res.data).then(res => {
    return res
}).catch(e => {

    throw e.response.data.message
})

export { GET_VENDAS,  POST_VENDAS,  PUT_VENDAS,  DELETE_VENDAS, instanceApiVenda };