<template>
  <div class="font-sans">
    <router-view/>
  </div>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  name: "App",
  components: {
  },
  /*setup() {
    const router = useRouter();
      router.push({ path: "/home" });
  },*/

}

</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
</style>
