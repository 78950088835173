<template>
    <div class="load flex place-items-center justify-center">
        <img src="../assets/images/loader.gif" width="60px">
    </div>
</template>

<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
    components: {

  },
})
</script>


<style>
    .load {
        position: fixed;
            background-color: rgba(0, 0, 0, 0.575);
            backdrop-filter: blur(15px);
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            z-index: 2;
    }
</style>
