<template>
  <div class="flex place-items-center flex-col">
    <div class="flex w-72 mt-12  .no-print">
      <div :class="{ 'bg-pink-900': pagina === 0, 'bg-gray-300': pagina === 1}" class="flex-1 p-1 rounded mr-2 mb-8">
      </div>
      <div :class="{ 'bg-pink-900': pagina === 1, 'bg-gray-300': pagina === 0}" class="flex-1 p-1 rounded mr-2 mb-8">
      </div>
    </div>
    <div v-if="pagina===0 && erro === false" class="mt-3 slide-in">
      <h1 class="uppercase  text-4xl font-bold text-violet-950 text-center w-72">Retire o seu ingresso aqui</h1>
    </div>
    <div v-else-if="pagina===1 && erro === true" class="mt-3 slide-in">
      <h1 class="uppercase  text-3xl font-bold text-violet-950 text-center w-72">Nenhum ingresso encontrado!</h1>
    </div>
    <div v-else-if="pagina===1 && erro === false && visible===true" class="mt-3 slide-in no-print">
      <h1 class="uppercase text-4xl font-bold text-violet-950 text-center w-72">Ingresso encontrado</h1>
    </div>

    <div v-if="pagina===1 && erro === false && visible===true" class="flutuante flex justify-center items-center text-white bg-gradient-to-r from-pink-700 to-pink-900 no-print">
    <button @click="gerarPDF" class="no-print">
      Baixar <fa icon="fa-solid fa-download" />
    </button>
    </div>
       
    <div class="flex flex-col mt-16">
      <p class="text-center font-bold tracking-wide text-lg m-3 slide-in" v-if="pagina===0">Pesquisar por CPF</p>

      <div class="flex flex-col slide-in mt-16" v-if="pagina === 0">
        <OptionInputs @cpfValido="atualizarPagina"/>
      </div>

      <div class="flex flex-col slide-in mt-1 printable-content " v-else-if="pagina === 1">
        <SeuIngresso :cpfValues="cpfValues" @load:error="handleErrorLoad" @setLoading:load="setLoading"/>
      </div>
    </div>
  </div>
  <div>
    <Loading v-show="showLoading" />
  </div>


</template>
<script lang="ts">
import {  ref } from 'vue';
import OptionInputs from '@/components/optionInputs.vue';
import SeuIngresso from '@/components/seuIngresso.vue';
import Loading from '@/components/loadingScreen.vue';

export default {
  name: 'HomeView',
  components: {
    //OptionButtons,
    OptionInputs,
    SeuIngresso,
    Loading,
  },
  data() {
    return {
     
    };
  },
  methods: {
    async gerarPDF() {
      window.print()
    }
    
  },
  setup() {
    const pagina = ref(0)
    const cpfValues = ref('')
    const erro = ref(false)
    const visible = ref(false)
    const showLoading = ref(false)

    const atualizarPagina = (cpfValido: boolean, cpfValue: string) => {
      showLoading.value = true;
      
      if (cpfValido && cpfValue !== '') {
        cpfValues.value = cpfValue;
        pagina.value = 1;
      } else {
        pagina.value = 0;
      }
      showLoading.value = false;
   
    };

    const handleErrorLoad = (erroV: boolean, visibleV: boolean) => {
      pagina.value = 1
      erro.value = erroV
      visible.value = visibleV
    }

    const setLoading = (setLoading: boolean) => {
      showLoading.value = setLoading
    }

    return { pagina, cpfValues, atualizarPagina, erro, handleErrorLoad, visible, showLoading, setLoading  /*,resetValue*/};
  }
};
</script>

<style>


.slide-in {
  animation: slideIn 0.5s ease forwards;
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.slide-out {
  animation: slideOut 0.5s ease forwards;
}

@keyframes slideOut {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.flutuante {
  position: fixed;
  right: 0px;
  bottom: 0px;
  margin: 15px;
  padding: 10px;
  font-weight: bold;
  border-radius: 30px;
  z-index: 999;
}

@media screen {
  .printable-content {
    display: none;
  }
}
@media print {
  @page {
    size: Legal; /* Define o tamanho do papel como A4 */
    margin: 20mm; /* Define as margens como 20mm */
  }
  .no-print {
    display: none;
  }
  .printable-content {
    display: block;
    scale: 1.2;
  }
}
</style>
