<template>
    <div class="flex flex-col place-items-center">
      <input v-model="cpfValue" type="text" id="cpf" v-maska data-maska="###.###.###-##" :placeholder="'Digite seu CPF'" 
      :class="{'border-b border-red-400 inputFalse': validade === false, 'border-b border-gray-400 inputTrue': validade === true}" 
      class="p-4 w-72 text-gray-600" inputmode="numeric"/>

      <div class="text-red-600 font-bold mt-3" v-if="validade === false">CPF INVÁLIDO</div>

      <button class="bg-gradient-to-r from-pink-700 to-pink-900 p-7 w-72 text-white rounded font-bold m-2 text-lg mt-14" @click="imprimir">
        Retirar Ingresso <fa icon="fa-solid fa-arrow-right-long"/>
      </button>

    </div>

    <!-- <button @click="resetValue" class="text-6xl mt-12">
      <fa icon="fa-solid fa-arrow-left" />
    </button>-->
</template>

<script>
import { defineComponent, ref } from 'vue';
import { vMaska } from "maska"
import { cpf } from 'cpf-cnpj-validator'; 

export default defineComponent({
  directives: {
    maska: vMaska
  },
  setup(_, {emit}) {
    const validade = ref(true)
    const cpfValue = ref('');

    const imprimir = () => {
      //cpfValue.value = cpfValue.value.replaceAll('.', '')
      //cpfValue.value = cpfValue.value.replaceAll('-', '')
      
      if(cpf.isValid(cpfValue.value) === false)
        validade.value = false
      else if(cpf.isValid(cpfValue.value) === true)
      {
        validade.value = true
        emit('cpfValido', true, cpfValue.value);
      }
        
    }

    return {imprimir, validade, cpfValue, emit}
  },
  methods: {

  }
});
</script>

<style>
  .inputFalse::placeholder {
    color: red;
    font-size: 19px;
  }
  .inputTrue::placeholder {
    color: gray;
    font-size: 19px;
  }
  input {
    font-size: 19px;
  }

</style>