<template>
  <div class="flex flex-col mb-5" v-if="value_api" id="teste">
    <div class="flex justify-center items-center">
      <img src="https://santocartao-files.s3.amazonaws.com/assets_voucher/logo.png" alt="Santo Cartao" class="">
    </div>
    <div class="flex justify-center items-center my-6">
      <span class="w-60 text-center" style="font-size: 20px;">Olá, {{ value_api.dados_vendas.nome }}, segue sua
        compra:</span>
    </div>
    <div v-for="(values, index) in value_api.ingressos" :key="index" class="flex flex-col">

<div v-if="index > 0" class="flex justify-center my-5">
  <img :src='`${assets}assets_voucher/iconPlus.png`' alt="icon Plus">
</div>

<div class="flex flex-col justify-center items-center">
  <div class="flex flex-col">
    <div class="flex justify-center items-center flex-col ">
      <img :src='`${assets}assets_voucher/top-ticket.png`' alt="">
      <img :src='`${assets}product-pictures/${values.cod_produto}.jpg`' alt="Produto"
        style="width: 238px; height: 238px;">
      <div class="flex flex-col content-center w-60" style="margin: 10px;">
        <p class="text-center p-2 font-semibold" style="font-size: 13px;">

          {{ values.nome_evento }}
          <span class=""
            v-if="formatDate(new Date(values.data_inicio)) === formatDate(new Date(values.data_fim))">
            <br>
            {{ formatDate(new Date(values.data_inicio)) }}
          </span>
          <span class="" v-else-if="formatDate(values.data_inicio) !== formatDate(values.data_fim)">
            <br>
            {{ formatDate(values.data_inicio) }} Até
            {{ formatDate(values.data_fim) }}
          </span>

          <br>
          <span class=""> {{ values.detalhes }}</span>
        </p>

      </div>
    </div>
    <div class="flex flex-col content-center" style="gap: 10px; margin-bottom: 10px;">
      <p class="text-center " style="padding:0px; line-height:8px; font-size:12px; font-weight:normal">Número do
        ingresso </p>
      <span class="font-bold text-center text-red-500"
        style="padding:0px;font-size:20px;font-weight:bold;color:#ed1c24">
        {{ `${values.transacao}/${values.identificador}` }}
      </span>
      <p class="text-center " style="padding:0px; line-height:8px; font-size:12px; font-weight:normal">Promoter {{
        values.nome_promoter }} </p>
      <p class="text-center " style="padding:0px; line-height:8px; font-size:12px; font-weight:normal">CPF {{
        values.documento_promoter }} </p>
      <p class="text-center " style="padding:0px; line-height:8px; font-size:12px; font-weight:normal">
        {{ value_api.dados_vendas.nome }} </p>
      <p class="text-center " style="padding:0px; line-height:8px; font-size:12px; font-weight:normal">
        {{ value_api.dados_vendas.cpf }} </p>
    </div>
    <div class="flex justify-center">
      <img :src='`${assets}assets_voucher/${values.qrcode}.png`' alt="QRCode" width="115" height="115">
    </div>
    
    <div class="pos" id="testes">
      <div class="custom-list-style" v-html="values.regras_produto"></div>
    </div>

    <div class="flex justify-center mt-5">
      <img :src='`${assets}assets_voucher/down-ticket.png`' alt="Down Ticket">
    </div>
  </div>
</div>
</div>

  </div>

  <button
    class="bg-gradient-to-r from-pink-700 to-pink-900 p-7 w-72 text-white rounded font-bold m-2 mb-10 text-lg mt-14 no-print"
    @click="refreshPage">
    VOLTAR
  </button>

  
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { POST_VENDAS } from "../services/api";
import moment from "moment-timezone";


export default defineComponent({
  components: {

  },
  props: {
    cpfValues: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      value_api: null,
      id_evento_url: 0,
      erro: false,
      visible: false,
    };
  },
  setup() {
    const assets = ref('https://santocartao-files.s3.amazonaws.com/')
    const router = useRouter();
    const refreshPage = () => {
      router.go(0);
    }

    return { assets, refreshPage }
  },
  mounted() {
    this.id_evento_url = this.$route.params.id;
    this.getVendas();
  },
  methods: {

    async getVendas() {
      try {
        this.$emit('setLoading:load', true);
        const response = await POST_VENDAS('/venda/ingressos', {
          cpf: this.cpfValues,
          id_evento: parseInt(this.id_evento_url)
        });

        if (Object.keys(response.data).length === 0)
          this.$emit('load:error', !this.erro, this.visible);
        else {
          this.value_api = response.data
          this.$emit('load:error', this.erro, !this.visible);
        }

        this.$emit('setLoading:load', false);
      } catch (error) {
        this.$emit('load:error', !this.erro, this.visible);
        this.$emit('setLoading:load', false);
        console.error('Erro ao obter ingressos:', error);
      }
    },

    formatDate(date) {
      return moment(date).tz('America/Sao_Paulo').format('DD/MM');
    },
  }

});
</script>


<style>
.custom-list-style ol {
  list-style-type: decimal !important;
  font-size: 14px;
}

.custom-list-style ul {
  list-style-type: disc !important;
  font-size: 14px;
}

.custom-list-style li {
  margin-left: 15px;
}

.pos {
  margin-top: 10px;
  margin-left: 35px;
}

@media screen {
  .printable-content {
    display: none;
  }
}
@media print {
  .no-print {
    display: none;
  }
  .printable-content {
    display: block;
  }
}
</style>